import React, { useRef, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";
import axios from "axios";

import ENV from "../../env";
import Spinner from "../Spinner";

const FederatedAuth = () => {
    const search = useLocation().search;

    const code = new URLSearchParams(search).get("code");
    const state = new URLSearchParams(search).get("state");

    const isInit = useRef(true);

    useEffect(() => {
      if (isInit.current) {
        isInit.current = false;
        
        // Validate code and state
        if (code && state) {
          if (state === "Google") {
    
            axios.post("https://tlc.auth.us-east-1.amazoncognito.com/oauth2/token", new URLSearchParams({
              grant_type: 'authorization_code',
              client_id: ENV['COGNITO_GOOGLE_CLIENT_ID'],
              code: code,
              redirect_uri: `${ENV.BASE_UI_URL}/auth`
              // redirect_uri: "http://localhost:3000/auth"
            }), {
              auth: {
                username: ENV['COGNITO_GOOGLE_CLIENT_ID'],
                password: ENV['COGNITO_GOOGLE_CLIENT_SECRET']
              }
            })
            .then( async (response) => {
              const decoded = jwtDecode(response.data.id_token);
      
              await axios.get(ENV.BASE_URL + "/users", {
                headers: { Authorization: `Bearer ${response.data.id_token}` },
              })
              .then( async () => {
                // Clear local storage
                localStorage.clear();
    
                // Set tokens in local storage
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.idToken`, response.data.id_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.accessToken`, response.data.access_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.refreshToken`, response.data.refresh_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.clockDrift`, 0);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.LastAuthUser`, decoded.sub);
                
                // Navigate to home page
                window.location.href = "/";
              })
              .catch( async (error) => {
                if (error.response.status === 404) {
                  if (error.response.data?.error === "User not found") {
                    // Sign up user
                    await axios.post(
                      ENV.BASE_URL + "/users",
                      {
                        "user-id": decoded.sub,
                        "email": decoded.email,
                        "full-name": decoded.email.split("@")[0].replace(".", " ").replace("_", " ").replace("-", " "),
                      },
                      {
                        headers: { Authorization: `Bearer ${response.data.id_token}` },
                      }
                    )
                    .then( async () => {
                      // Confirm user
                      await axios.patch(ENV.BASE_URL + "/users", {
                        "status": "CONFIRMED"
                      }, {
                        headers: { Authorization: `Bearer ${response.data.id_token}` },
                      }).then( async () => {
                        // Clear local storage
                        localStorage.clear();
    
                        // Set tokens in local storage
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.idToken`, response.data.id_token);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.accessToken`, response.data.access_token);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.refreshToken`, response.data.refresh_token);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.clockDrift`, 0);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.LastAuthUser`, decoded.sub);
                        
                        // Navigate to home page
                        window.location.href = "/";
                      }
                      ).catch((error) => {
                        console.log(error);
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  }
                }
              });
            })
            .catch(() => {
              window.location.href = "/";
            });
          }
          else if (state === "Facebook") {
            axios.post("https://tlc.auth.us-east-1.amazoncognito.com/oauth2/token", new URLSearchParams({
              grant_type: 'authorization_code',
              client_id: ENV['COGNITO_FACEBOOK_CLIENT_ID'],
              code: code,
              redirect_uri: `${ENV.BASE_UI_URL}/auth`
              // redirect_uri: "http://localhost:3000/auth"
            }), {
              auth: {
                username: ENV['COGNITO_FACEBOOK_CLIENT_ID'],
                password: ENV['COGNITO_FACEBOOK_CLIENT_SECRET']
              }
            })
            .then( (response) => {
              const decoded = jwtDecode(response.data.id_token);
    
              axios.get(ENV.BASE_URL + "/users", {
                headers: { Authorization: `Bearer ${response.data.id_token}` },
              })
              .then(() => {
                // Clear local storage
                localStorage.clear();
    
                // Set tokens in local storage
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.idToken`, response.data.id_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.accessToken`, response.data.access_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.refreshToken`, response.data.refresh_token);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.clockDrift`, 0);
                localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.LastAuthUser`, decoded.sub);
                
                // Navigate to home page
                window.location.href = "/";
              })
              .catch((error) => {
                if (error.response.status === 404) {
                  if (error.response.data?.error === "User not found") {
                    // Sign up user
                    axios.post(
                      ENV.BASE_URL + "/users",
                      {
                        "user-id": decoded.sub,
                        "email": decoded.email,
                        "full-name": decoded.email.split("@")[0].replace(".", " ").replace("_", " ").replace("-", " "),
                      },
                      {
                        headers: { Authorization: `Bearer ${response.data.id_token}` },
                      }
                    )
                    .then(() => {
                      // Confirm user
                      axios.patch(ENV.BASE_URL + "/users", {
                        "status": "CONFIRMED"
                      }, {
                        headers: { Authorization: `Bearer ${response.data.id_token}` },
                      }).then(() => {
                        // Clear local storage
                        localStorage.clear();
    
                        // Set tokens in local storage
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.idToken`, response.data.id_token);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.accessToken`, response.data.access_token);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.refreshToken`, response.data.refresh_token);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.${decoded.sub}.clockDrift`, 0);
                        localStorage.setItem(`CognitoIdentityServiceProvider.4uf6fbvprk3nfpjavm68f0dqsv.LastAuthUser`, decoded.sub);
                        
                        // Navigate to home page
                        window.location.href = "/";
                      }
                      ).catch((error) => {
                        console.log(error);
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  }
                }
              });
            }, (error) => {
              console.log(error);
            });
          }
        }
        else {
          console.log("No code or state");
          window.location.href = "/";
        }
      }
      // eslint-disable-next-line
      }, []);

    return (
      <>
        <Spinner />
      </>
    )
}

export default FederatedAuth;